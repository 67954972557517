import React from "react";
import { render } from "react-dom";
import {
  NODE_ENV,
  STOTAGE_TOKEN_NAME,
  APP_VERSION,
  BUILD_ENV
} from "utils/constants";

const init = async() => {
  // eslint-disable-next-line no-console
  console.log(`APP_VERSION: ${APP_VERSION}\nBUILD_ENV: ${BUILD_ENV}\nNODE_ENV: ${NODE_ENV}`);

  const { default: App } = await import("./App");

  render(<App />, document.getElementById("root"));
};

init();

window.addEventListener("storage", ({ key, oldValue, newValue }) => {
  if (key === STOTAGE_TOKEN_NAME && (oldValue !== newValue)) {
    window.location.href = newValue ? "/" : "/signin";
  }
});
