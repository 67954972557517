/* eslint-disable */
export const NODE_ENV = process.env.NODE_ENV;
export const SENTRY_KEY = process.env.SENTRY_KEY;
export const SENTRY_PROJECT = process.env.SENTRY_PROJECT;
export const API_BASE = process.env.API_BASE;
export const APP_VERSION = process.env.APP_VERSION;
export const BUILD_ENV = process.env.BUILD_ENV;
/* eslint-enable */

export const PRODUCTION_ENV = "production"; // Master & qa
export const STAGING_ENV = "stage"; // Staging
export const DEVELOPMENT_ENV = "development"; // Local
export const STOTAGE_TOKEN_NAME = "auth-token";
export const STOTAGE_USER_LANG = "user-lang";
export const DEV_MODE = NODE_ENV === DEVELOPMENT_ENV;
export const NON_NUMERIC_REGX = /[^0-9.]+/g;
export const DUE_DAYS_RED = 1;
export const DUE_DAYS_YELLOW = 3;
export const ROOMING_DUE_DAYS_YELLOW = 10;
export const DUE_DAYS_DANGER = 1;
export const DUE_DAYS_WARNING = 3;
export const ROOMING_DUE_DAYS_DANGER = 2;
export const ROOMING_DUE_DAYS_WARNING = 10;
export const SECOND = 1000;
// eslint-disable-next-line no-magic-numbers
export const MINUTE = SECOND * 60;
// eslint-disable-next-line no-magic-numbers
export const HOUR = MINUTE * 60;
// eslint-disable-next-line no-magic-numbers
export const DAY = HOUR * 24;
export const TIMEZONE_OFFSET = (new Date().getTimezoneOffset() * MINUTE);
// eslint-disable-next-line no-magic-numbers
export const CET_OFFSET = -60 * MINUTE;
// eslint-disable-next-line no-magic-numbers
export const BACKOFFICE_END_OF_DAY = 20 + (TIMEZONE_OFFSET / HOUR) - (CET_OFFSET / HOUR);
export const SERVER_SHORT_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_SHORT_FORMAT = "DD.MM";
export const TIME_FORMAT = "HH:mm:ss";
export const SERVER_FORMAT = `${SERVER_SHORT_FORMAT} 00:00:00`;
export const DATE_TIME_FORMAT = `${SERVER_SHORT_FORMAT} ${TIME_FORMAT}`;
export const MOMENT_MIDNIGHT = Object.freeze({
  hour: 0, minute: 0, second: 0, milliseconds: 0
});
export const DEFAULT = "DEFAULT";
export const AGENCY_SESSION_REQUEST = "AGENCY_SESSION_REQUEST";
export const HOTEL_PROPOSAL = "HOTEL_PROPOSAL";
export const AGENCY_PROPOSAL = "AGENCY_PROPOSAL";
export const AGENCY_CONFIRM = "AGENCY_CONFIRM";
export const AGENCY_CANCEL_REQUEST = "AGENCY_CANCEL_REQUEST";
export const AGENCY_CANCEL_REQUEST_CONFIRMED = "AGENCY_CANCEL_REQUEST_CONFIRMED";
export const CANCELED = "CANCELED";
export const SESSION_STATUSES = [
  DEFAULT,
  AGENCY_SESSION_REQUEST,
  HOTEL_PROPOSAL,
  AGENCY_PROPOSAL,
  AGENCY_CONFIRM,
  AGENCY_CANCEL_REQUEST,
  AGENCY_CANCEL_REQUEST_CONFIRMED,
  CANCELED
];
export const CANCEL_AGENCY_PROPOSAL = "CANCEL_AGENCY_PROPOSAL";
export const CREATED = "created";
export const UNCONFIRMED_PROPOSALS_CANCEL = "UNCONFIRMED_PROPOSALS_CANCEL";
export const UNCONFIRMED_PROPOSALS_CONFIRMED = "UNCONFIRMED_PROPOSALS_CONFIRMED";
export const UNCONFIRMED_PROPOSALS_REQUEST = "UNCONFIRMED_PROPOSALS_REQUEST";
export const UNCONFIRMED_PROPOSALS_STATUS_REQUESTED = "REQUESTED";
export const UNCONFIRMED_PROPOSALS_STATUS_CONFIRMED = "CONFIRMED";
export const ROOMING_STATUS_CONFIRMED = "CONFIRMED";
export const ROOMING_STATUS_CANCELED = "CANCELED";
export const ROOMING_STATUS_WAIT_FOR_CONFIRMATION = "WAIT_FOR_CONFIRMATION";
export const PROLONG_STATUS_REQUEST = "PROLONG_STATUS_REQUEST";
export const OPTION_RESTORE_STATUS_REQUEST = "OPTION_RESTORE_STATUS_REQUEST";
export const AGENCY_CANCEL_REQUEST_CANCEL = "AGENCY_CANCEL_REQUEST_CANCEL";
export const MSG_TYPE_EXPENSIVE = "MSG_TYPE_EXPENSIVE";
export const MSG_TYPE_TOO_FAR = "MSG_TYPE_TOO_FAR";
export const MSG_TYPE_HOTEL_LEVEL = "MSG_TYPE_HOTEL_LEVEL";
export const MSG_TYPE_CUSTOM = "MSG_TYPE_CUSTOM";
export const MSG_TYPE_HOTEL = "MSG_TYPE_HOTEL";
export const MSG_TYPE_CANCEL = "MSG_TYPE_CANCEL";
export const MSG_TYPE_DIRECTLY = "MSG_TYPE_DIRECTLY";
export const MSG_TYPE_RESTORE_OPTION = "MSG_TYPE_RESTORE_OPTION";
export const STATUS_HOTEL_RESTORE = "RESTORE";
export const STATUS_HOTEL_DECLINED = "DECLINED";
export const OFFER_STATUS_RESTORE = "RESTORE";
export const OFFER_STATUS_DECLINED = "DECLINED";
export const DECLINED_MESSAGES = [
  MSG_TYPE_EXPENSIVE,
  MSG_TYPE_TOO_FAR,
  MSG_TYPE_HOTEL_LEVEL,
  MSG_TYPE_CUSTOM
];
export const defaultFoxValue = 25;
export const CURRENCY_EURO = "€";
export const CURRENCY_EURO_ABBR = "EUR";
export const FIRST_DAY_OF_THE_WEEK = 1;
export const formatDMY = "DD/MM/YYYY";
export const MIN_ROOMS_AMOUNT = 10;
export const ROOM_TYPE_SINGLE = "ROOM_TYPE_SINGLE";
export const ROOM_TYPE_TWIN = "ROOM_TYPE_TWIN";
export const ROOM_TYPE_DOUBLE = "ROOM_TYPE_DOUBLE";
export const ROOM_TYPE_TRIPLE = "ROOM_TYPE_TRIPLE";
export const ROOM_TYPE_QUAD = "ROOM_TYPE_QUAD";

const ROOM_TYPE_SINGLE_CAPACITY = 1;

const ROOM_TYPE_TWIN_CAPACITY = 2;

const ROOM_TYPE_DOUBLE_CAPACITY = 2;

const ROOM_TYPE_TRIPLE_CAPACITY = 3;

const ROOM_TYPE_QUAD_CAPACITY = 4;

export const ROOM_TYPE_SINGLE_ID = "5887434177ce1500fad4cfad";
export const ROOM_TYPE_TWIN_ID = "5887436277ce1500fad4cfae";
export const ROOM_TYPE_DOUBLE_ID = "58adb2a47aefe204ca56ca46";
export const ROOM_TYPE_TRIPLE_ID = "588743eb77ce1500fad4cfb0";
export const ROOM_TYPE_QUAD_ID = "5887441077ce1500fad4cfb1";
export const ROOMS_FOC_ORDER = [
  ROOM_TYPE_TWIN_ID,
  ROOM_TYPE_DOUBLE_ID,
  ROOM_TYPE_TRIPLE_ID,
  ROOM_TYPE_SINGLE_ID
];
export const ROOM_TYPE_SINGLE_PARAMS = {
  _id: ROOM_TYPE_SINGLE_ID,
  name: ROOM_TYPE_SINGLE,
  abbr: `${ROOM_TYPE_SINGLE}_ABBR`,
  capacity: ROOM_TYPE_SINGLE_CAPACITY
};
export const ROOM_TYPE_TWIN_PARAMS = {
  _id: ROOM_TYPE_TWIN_ID,
  name: ROOM_TYPE_TWIN,
  abbr: `${ROOM_TYPE_TWIN}_ABBR`,
  capacity: ROOM_TYPE_TWIN_CAPACITY
};
export const ROOM_TYPE_DOUBLE_PARAMS = {
  _id: ROOM_TYPE_DOUBLE_ID,
  name: ROOM_TYPE_DOUBLE,
  abbr: `${ROOM_TYPE_DOUBLE}_ABBR`,
  capacity: ROOM_TYPE_DOUBLE_CAPACITY
};
export const ROOM_TYPE_TRIPLE_PARAMS = {
  _id: ROOM_TYPE_TRIPLE_ID,
  name: ROOM_TYPE_TRIPLE,
  abbr: `${ROOM_TYPE_TRIPLE}_ABBR`,
  capacity: ROOM_TYPE_TRIPLE_CAPACITY
};
export const ROOM_TYPE_QUAD_PARAMS = {
  _id: ROOM_TYPE_QUAD_ID,
  name: ROOM_TYPE_QUAD,
  abbr: `${ROOM_TYPE_QUAD}_ABBR`,
  capacity: ROOM_TYPE_QUAD_CAPACITY
};
export const ROOM_TYPES = [
  ROOM_TYPE_SINGLE_PARAMS,
  ROOM_TYPE_TWIN_PARAMS,
  ROOM_TYPE_DOUBLE_PARAMS,
  ROOM_TYPE_TRIPLE_PARAMS,
  ROOM_TYPE_QUAD_PARAMS
];
export const ROOM_TYPE_NAMES = Object.freeze([
  ROOM_TYPE_SINGLE,
  ROOM_TYPE_TWIN,
  ROOM_TYPE_DOUBLE,
  ROOM_TYPE_TRIPLE,
  ROOM_TYPE_QUAD
]);
export const ROOM_TYPE_I18N_MAP = Object.freeze({
  [ROOM_TYPE_SINGLE]: "room_type.ROOM_TYPE_SINGLE",
  [ROOM_TYPE_TWIN]: "room_type.ROOM_TYPE_TWIN",
  [ROOM_TYPE_DOUBLE]: "room_type.ROOM_TYPE_DOUBLE",
  [ROOM_TYPE_TRIPLE]: "room_type.ROOM_TYPE_TRIPLE",
  [ROOM_TYPE_QUAD]: "room_type.ROOM_TYPE_QUAD"
});
export const ROOM_TYPE_ABBR_I18N_MAP = Object.freeze({
  [ROOM_TYPE_SINGLE]: "room_type.ROOM_TYPE_SINGLE_ABBR",
  [ROOM_TYPE_TWIN]: "room_type.ROOM_TYPE_TWIN_ABBR",
  [ROOM_TYPE_DOUBLE]: "room_type.ROOM_TYPE_DOUBLE_ABBR",
  [ROOM_TYPE_TRIPLE]: "room_type.ROOM_TYPE_TRIPLE_ABBR",
  [ROOM_TYPE_QUAD]: "room_type.ROOM_TYPE_QUAD_ABBR"
});
export const ROOM_ID_TO_NAME_MAP = Object.freeze(
  ROOM_TYPES.reduce((result, { _id, name }) => ({ ...result, [_id]: name }), {})
);
export const ROOM_ID_MAP = ROOM_TYPES.reduce((result, room) => ({ ...result, [room._id]: room }), {});
export const ROOM_TYPES_ORDER = Object.freeze(ROOM_TYPES.map(({ name }) => name));
export const ROOM_ORDER_BY_ID = Object.freeze(ROOM_TYPES.map(({ _id }) => _id));
export const MAX_LOADING_ATTEMPT = 3;
export const ROOMING_TYPE_PRELIMINARY = "preliminary";
export const ROOMING_TYPE_FINAL = "final";
export const FREE_PARKING = "FREE";
export const GROUP_TYPE_LEISURE = "LEISURE";
export const GROUP_TYPE_CORPORATE = "CORPORATE";
export const GROUP_TYPE_STUDENTS = "STUDENTS";
export const GROUP_TYPE_BULK_REQUEST = "BULK_REQUEST";
export const GROUP_TYPE_DIRECT_REQUEST = "DIRECT_REQUEST";
export const GROUP_TYPE_DEFAULT = GROUP_TYPE_LEISURE;
export const GROUP_TYPES = Object.freeze([
  GROUP_TYPE_LEISURE,
  GROUP_TYPE_CORPORATE,
  GROUP_TYPE_STUDENTS,
  GROUP_TYPE_BULK_REQUEST,
  GROUP_TYPE_DIRECT_REQUEST
]);
export const GROUP_TYPES_I18N_MAP = Object.freeze({
  [GROUP_TYPE_LEISURE]: "group_type.LEISURE",
  [GROUP_TYPE_CORPORATE]: "group_type.CORPORATE",
  [GROUP_TYPE_STUDENTS]: "group_type.STUDENTS",
  [GROUP_TYPE_BULK_REQUEST]: "group_type.BULK_REQUEST",
  [GROUP_TYPE_DIRECT_REQUEST]: "group_type.TEST_REQUEST"
});
export const MEAL_TYPE_BB = "BB";
export const MEAL_TYPE_CB = "CB";
export const MEAL_TYPE_AB = "AB";
export const MEAL_TYPE_HB = "HB";
export const MEAL_TYPE_FB = "FB";
export const MEAL_TYPE_NMP = "NMP";
export const MEAL_TYPE_DEFAULT = MEAL_TYPE_BB;
export const MEAL_TYPES = Object.freeze([
  MEAL_TYPE_BB,
  MEAL_TYPE_CB,
  MEAL_TYPE_AB,
  MEAL_TYPE_HB,
  MEAL_TYPE_FB,
  MEAL_TYPE_NMP
]);
export const MEAL_TYPES_SHORT = Object.freeze([
  MEAL_TYPE_BB,
  MEAL_TYPE_HB,
  MEAL_TYPE_FB
]);
export const MEAL_TYPES_I18N = Object.freeze({
  [MEAL_TYPE_BB]: "buffet_breakfast",
  [MEAL_TYPE_CB]: "continental_breakfast",
  [MEAL_TYPE_AB]: "american_breakfast",
  [MEAL_TYPE_HB]: "half_board",
  [MEAL_TYPE_FB]: "full_board",
  [MEAL_TYPE_NMP]: "no_meal_plan"
});
export const CITY_TAX_TYPE_INCLUDED = "include";
export const CITY_TAX_TYPE_NOT_INCLUDED = "notInclude";
export const CITY_TAX_TYPES = Object.freeze([
  CITY_TAX_TYPE_INCLUDED,
  CITY_TAX_TYPE_NOT_INCLUDED
]);
export const EMPTY_STRING = "";
export const BUS_PARKING_NA = "NA";
export const BUS_PARKING_FREE = "FREE";
export const BUS_PARKING_PAID = "PAID";
export const BUS_PARKING_TYPES = Object.freeze([
  BUS_PARKING_NA,
  BUS_PARKING_FREE,
  BUS_PARKING_PAID
]);
export const BUS_PARKING_TYPES_I18N = Object.freeze({
  [BUS_PARKING_NA]: "bus_parking.not_available",
  [BUS_PARKING_FREE]: "bus_parking.free",
  [BUS_PARKING_PAID]: "bus_parking.paid"
});
export const MSG_TYPE_CHANGE = "MSG_TYPE_CHANGE";
export const MIN_REQUESTS_TO_CHECK_USER_RATING = 5;
export const MIN_USER_RATING_TO_DECLINE_FREE = 1;
export const USER_DECLINED_SESSION_PENALTY = 1;
export const APP_LOCALE_EN = "en";
export const APP_LOCALE_DE = "de";
export const APP_LOCALE_RU = "ru";
export const APP_LOCALE_ZH = "zh";
export const APP_LOCALE_SK = "sk";
export const APP_LOCALES = [
  APP_LOCALE_EN,
  APP_LOCALE_DE,
  APP_LOCALE_RU,
  APP_LOCALE_ZH
  // APP_LOCALE_SK,
];
export const APP_LOCALE_DEFAULT = APP_LOCALE_EN;
export const LOCALES_I18N_MAP = {
  [APP_LOCALE_EN]: "profile.language_en",
  [APP_LOCALE_DE]: "profile.language_de",
  [APP_LOCALE_RU]: "profile.language_ru",
  [APP_LOCALE_ZH]: "profile.language_zh",
  [APP_LOCALE_SK]: "profile.language_sk"
};
export const CONTACT_PHONE = "+‭44 20 3807 7340‬";
export const CONTACT_MOBILE = "+420 776 775 606";
export const CONTACT_SKYPE = "info@davincits.com";
export const CONTACT_EMAIL = "info@davincits.com";
export const CONTACT_EMAIL_SAILS = "sales@davincits.com";
export const WHITELIST_COMPANIES = Object.freeze(["593660b76666e2365120db40"]);
// eslint-disable-next-line no-magic-numbers
export const HOTEL_RATES = Object.freeze([-1, 1, 2, 3, 4, 5]);
export const SEARCH_BY_RADIUS = "RADIUS";
export const SEARCH_BY_LOCATION = "SPECIFIC";
export const SEARCH_BY_WAY = "DIRECTION";
export const SEARCH_BY_CITIES = "ALTERNATIVE";
export const SPACE_LOCATION_INSIDE = "INSIDE";
export const SPACE_LOCATION_OUTSIDE = "OUTSIDE";
export const SPACE_LOCATION_TYPES = Object.freeze([
  SPACE_LOCATION_OUTSIDE,
  SPACE_LOCATION_INSIDE
]);
export const SPACE_LOCATION_I18N_MAP = Object.freeze({
  [SPACE_LOCATION_INSIDE]: "mice.space_location.inside",
  [SPACE_LOCATION_OUTSIDE]: "mice.space_location.outside"
});
export const TIME_FRAME_HALF_DAY = "HALF_DAY";
export const TIME_FRAME_FULL_DAY = "FULL_DAY";
export const TIME_FRAME_EVENING = "EVENING";
export const TIME_FRAME_24_HOURS = "HOLD_24HR";
export const TIME_FRAME_TYPES = Object.freeze([
  TIME_FRAME_HALF_DAY,
  TIME_FRAME_FULL_DAY,
  TIME_FRAME_EVENING,
  TIME_FRAME_24_HOURS
]);
export const TIME_FRAME_I18N_MAP = Object.freeze({
  [TIME_FRAME_HALF_DAY]: "mice.time_frame.hal_day",
  [TIME_FRAME_FULL_DAY]: "mice.time_frame.full_day",
  [TIME_FRAME_EVENING]: "mice.time_frame.evening",
  [TIME_FRAME_24_HOURS]: "mice.time_frame.24_hours"
});
export const ROOM_LAYOUT_BANQUET_5 = "BANQUET_5";
export const ROOM_LAYOUT_BANQUET_6 = "BANQUET_6";
export const ROOM_LAYOUT_CRESCENT = "CRESCENT";
export const ROOM_LAYOUT_CLASSROOM = "CLASSROOM";
export const ROOM_LAYOUT_THEATER = "THEATER";
export const ROOM_LAYOUT_BOARDROOM = "BOARDROOM";
export const ROOM_LAYOUT_HOLLOW_SQUARE = "HOLLOW_SQUARE";
export const ROOM_LAYOUT_RECEPTION = "RECEPTION";
export const ROOM_LAYOUT_CONVENTION_8X10 = "CONVENTION_8X10";
export const ROOM_LAYOUT_CONVENTION_10X10 = "CONVENTION_10X10";
export const ROOM_LAYOUT_U_SHAPE = "U_SHAPE";
export const ROOM_LAYOUT_REGISTRATION = "REGISTRATION";
export const ROOM_LAYOUT_TYPES = Object.freeze([
  ROOM_LAYOUT_BANQUET_5,
  ROOM_LAYOUT_BANQUET_6,
  ROOM_LAYOUT_CRESCENT,
  ROOM_LAYOUT_CLASSROOM,
  ROOM_LAYOUT_THEATER,
  ROOM_LAYOUT_BOARDROOM,
  ROOM_LAYOUT_HOLLOW_SQUARE,
  ROOM_LAYOUT_RECEPTION,
  ROOM_LAYOUT_CONVENTION_8X10,
  ROOM_LAYOUT_CONVENTION_10X10,
  ROOM_LAYOUT_U_SHAPE,
  ROOM_LAYOUT_REGISTRATION
]);
export const ROOM_LAYOUT_I18N_MAP = Object.freeze({
  [ROOM_LAYOUT_BANQUET_5]: "mice.room_layout.banquet_5",
  [ROOM_LAYOUT_BANQUET_6]: "mice.room_layout.banquet_6",
  [ROOM_LAYOUT_CRESCENT]: "mice.room_layout.crescent",
  [ROOM_LAYOUT_CLASSROOM]: "mice.room_layout.classroom",
  [ROOM_LAYOUT_THEATER]: "mice.room_layout.theater",
  [ROOM_LAYOUT_BOARDROOM]: "mice.room_layout.boardroom",
  [ROOM_LAYOUT_HOLLOW_SQUARE]: "mice.room_layout.hollow_square",
  [ROOM_LAYOUT_RECEPTION]: "mice.room_layout.reception",
  [ROOM_LAYOUT_CONVENTION_8X10]: "mice.room_layout.convention_8x10",
  [ROOM_LAYOUT_CONVENTION_10X10]: "mice.room_layout.convention_10x10",
  [ROOM_LAYOUT_U_SHAPE]: "mice.room_layout.u-shape",
  [ROOM_LAYOUT_REGISTRATION]: "mice.room_layout.registration"
});
export const SQUARE_SIZE_UNIT_METERS = "METERS";
export const SQUARE_SIZE_UNIT_FEET = "FEET";
export const SQUARE_SIZE_UNIT_TYPES = Object.freeze([
  SQUARE_SIZE_UNIT_METERS,
  SQUARE_SIZE_UNIT_FEET
]);
export const SQUARE_SIZE_UNIT_I18N_MAP = Object.freeze({
  [SQUARE_SIZE_UNIT_METERS]: "mice.room_size.sq_meters",
  [SQUARE_SIZE_UNIT_FEET]: "mice.room_size.sq_feet"
});
export const CATERING_SERVICE_COFEE_BREAK = "COFFEE_BREAKS_1";
export const CATERING_SERVICE_DBL_COFFEE_BREAK = "COFFEE_BREAKS_2";
export const CATERING_SERVICE_WATER = "WATER";
export const CATERING_SERVICE_COFFEE = "COFFEE";
export const CATERING_SERVICE_TEA = "TEA";
export const CATERING_SERVICE_SOFT_DRINKS = "SOFT_DRINKS";
export const CATERING_SERVICE_SNACKS = "SNACKS";
export const CATERING_SERVICE_SWEETNESS = "SWEETS_PASTRY";
export const CATERING_SERVICE_TYPES = Object.freeze([
  CATERING_SERVICE_COFEE_BREAK,
  CATERING_SERVICE_DBL_COFFEE_BREAK,
  CATERING_SERVICE_WATER,
  CATERING_SERVICE_COFFEE,
  CATERING_SERVICE_TEA,
  CATERING_SERVICE_SOFT_DRINKS,
  CATERING_SERVICE_SNACKS,
  CATERING_SERVICE_SWEETNESS
]);
export const CATERING_SERVICE_I18N_MAP = Object.freeze({
  [CATERING_SERVICE_COFEE_BREAK]: "mice.catering_service.coffee_break",
  [CATERING_SERVICE_DBL_COFFEE_BREAK]: "mice.catering_service.dbl_coffee_break",
  [CATERING_SERVICE_WATER]: "mice.catering_service.water",
  [CATERING_SERVICE_COFFEE]: "mice.catering_service.coffee",
  [CATERING_SERVICE_TEA]: "mice.catering_service.tea",
  [CATERING_SERVICE_SOFT_DRINKS]: "mice.catering_service.soft_drink",
  [CATERING_SERVICE_SNACKS]: "mice.catering_service.snacks",
  [CATERING_SERVICE_SWEETNESS]: "mice.catering_service.sweetness"
});
export const EQUIPMENT_DATA_PROJECTOR = "DATA_PROJECTOR";
export const EQUIPMENT_LAPTOP = "LAPTOP";
export const EQUIPMENT_MICROPHONES = "MICROPHONES";
export const EQUIPMENT_LOUDSPEAKERS = "LOUDSPEAKERS";
export const EQUIPMENT_LASER_POINTER = "LASER_POINTER";
export const EQUIPMENT_LIGHTING = "LIGHTING";
export const EQUIPMENT_PROJECTION_STANDS = "PROJECTION_STANDS";
export const EQUIPMENT_FLIP_CHART = "FLIP_CHART";
export const EQUIPMENT_WHITEBOARD = "WHITEBOARD";
export const EQUIPMENT_SPEECH_STAND = "SPEECH_STAND";
export const EQUIPMENT_NOTEPAD_PEN = "NOTEPAD_PEN";
export const EQUIPMENT_TYPES = Object.freeze([
  EQUIPMENT_DATA_PROJECTOR,
  EQUIPMENT_LAPTOP,
  EQUIPMENT_MICROPHONES,
  EQUIPMENT_LOUDSPEAKERS,
  EQUIPMENT_LASER_POINTER,
  EQUIPMENT_LIGHTING,
  EQUIPMENT_PROJECTION_STANDS,
  EQUIPMENT_FLIP_CHART,
  EQUIPMENT_WHITEBOARD,
  EQUIPMENT_SPEECH_STAND,
  EQUIPMENT_NOTEPAD_PEN
]);
export const EQUIPMENT_I18N_MAP = Object.freeze({
  [EQUIPMENT_DATA_PROJECTOR]: "mice.equipment.data_projector",
  [EQUIPMENT_LAPTOP]: "mice.equipment.laptop",
  [EQUIPMENT_MICROPHONES]: "mice.equipment.microphones",
  [EQUIPMENT_LOUDSPEAKERS]: "mice.equipment.loudspeakers",
  [EQUIPMENT_LASER_POINTER]: "mice.equipment.laser_pointer",
  [EQUIPMENT_LIGHTING]: "mice.equipment.lighting",
  [EQUIPMENT_PROJECTION_STANDS]: "mice.equipment.projection_stands",
  [EQUIPMENT_FLIP_CHART]: "mice.equipment.flip_chart",
  [EQUIPMENT_WHITEBOARD]: "mice.equipment.whiteboard",
  [EQUIPMENT_SPEECH_STAND]: "mice.equipment.speech_stand",
  [EQUIPMENT_NOTEPAD_PEN]: "mice.equipment.notepad_pen"
});
export const KEY_NAME_ENTER = "Enter";
export const WEEK_DAY_SU = "SU";
export const WEEK_DAY_MO = "MO";
export const WEEK_DAY_TU = "TU";
export const WEEK_DAY_WE = "WE";
export const WEEK_DAY_TH = "TH";
export const WEEK_DAY_FR = "FR";
export const WEEK_DAY_SA = "SA";
export const WEEK_DAYS = Object.freeze([
  WEEK_DAY_SU,
  WEEK_DAY_MO,
  WEEK_DAY_TU,
  WEEK_DAY_WE,
  WEEK_DAY_TH,
  WEEK_DAY_FR,
  WEEK_DAY_SA
]);
export const WEEK_DAYS_SHORT_I18N_MAP = Object.freeze({
  [WEEK_DAY_SU]: "day.short.su",
  [WEEK_DAY_MO]: "day.short.mo",
  [WEEK_DAY_TU]: "day.short.tu",
  [WEEK_DAY_WE]: "day.short.we",
  [WEEK_DAY_TH]: "day.short.th",
  [WEEK_DAY_FR]: "day.short.fr",
  [WEEK_DAY_SA]: "day.short.sa"
});
export const WEEK_START = WEEK_DAY_MO;
export const MONTH_NAME_JANUARY = "JANUARY";
export const MONTH_NAME_FEBRUARY = "FEBRUARY";
export const MONTH_NAME_MARCH = "MARCH";
export const MONTH_NAME_APRIL = "APRIL";
export const MONTH_NAME_MAY = "MAY";
export const MONTH_NAME_JUNE = "JUNE";
export const MONTH_NAME_JULY = "JULY";
export const MONTH_NAME_AUGUST = "AUGUST";
export const MONTH_NAME_SEPTEMBER = "SEPTEMBER";
export const MONTH_NAME_OCTOBER = "OCTOBER";
export const MONTH_NAME_NOVEMBER = "NOVEMBER";
export const MONTH_NAME_DECEMBER = "DECEMBER";
export const MONTH_NAMES = Object.freeze([
  MONTH_NAME_JANUARY,
  MONTH_NAME_FEBRUARY,
  MONTH_NAME_MARCH,
  MONTH_NAME_APRIL,
  MONTH_NAME_MAY,
  MONTH_NAME_JUNE,
  MONTH_NAME_JULY,
  MONTH_NAME_AUGUST,
  MONTH_NAME_SEPTEMBER,
  MONTH_NAME_OCTOBER,
  MONTH_NAME_NOVEMBER,
  MONTH_NAME_DECEMBER
]);
export const MONTH_NAMES_I18N_MAP = Object.freeze({
  [MONTH_NAME_JANUARY]: "months.january",
  [MONTH_NAME_FEBRUARY]: "months.february",
  [MONTH_NAME_MARCH]: "months.march",
  [MONTH_NAME_APRIL]: "months.april",
  [MONTH_NAME_MAY]: "months.may",
  [MONTH_NAME_JUNE]: "months.june",
  [MONTH_NAME_JULY]: "months.july",
  [MONTH_NAME_AUGUST]: "months.august",
  [MONTH_NAME_SEPTEMBER]: "months.september",
  [MONTH_NAME_OCTOBER]: "months.october",
  [MONTH_NAME_NOVEMBER]: "months.november",
  [MONTH_NAME_DECEMBER]: "months.december"
});

export const COLOR_CLASS_NAME_PRIMARY = "u-color-primary";
export const COLOR_CLASS_NAME_SUCCESS = "u-color-success";
export const COLOR_CLASS_NAME_WARNING = "u-color-warning";
export const COLOR_CLASS_NAME_DANGER = "u-color-danger";

export const PATH_BLOCKED = "/blocked";

export const HTTPS_STATUSE_OK = 200;
export const HTTPS_STATUSE_UNAUTHORIZED = 401;

export const REQUEST_METHOD_GET = "GET";
export const REQUEST_METHOD_POST = "POST";
export const REQUEST_METHOD_PUT = "PUT";
export const REQUEST_METHOD_DELETE = "DELETE";
export const REQUEST_METHOD_PATCH = "PATCH";
export const REQUEST_METHOD_HEAD = "HEAD";

export const ERROR_REQUEST = "requestError";
export const ERROR_UNAUTHORIZED = "Unauthorized";
export const ERROR_INVALID_TOKEN = "invalidToken";

export const RATING_VERY_HIGH = "VERY_HIGH";
export const RATING_HIGH = "HIGH";
export const RATING_MEDIUM = "MEDIUM";
export const RATING_LOW = "LOW";
export const RATING_VERY_LOW = "VERY_LOW";
export const RATING_ANY = "ANY";
export const RATINGS = Object.freeze([
  RATING_ANY,
  RATING_VERY_LOW,
  RATING_LOW,
  RATING_MEDIUM,
  RATING_HIGH,
  RATING_VERY_HIGH
]);

const RATING_TEMPLATE = [
  { star: -1 },
  { star: 1 },
  { star: 2 },
  // eslint-disable-next-line no-magic-numbers
  { star: 3 },
  // eslint-disable-next-line no-magic-numbers
  { star: 4 },
  // eslint-disable-next-line no-magic-numbers
  { star: 5 }
];

export const RATING_TO_STARS_MAP = Object.freeze(
  RATINGS.reduce(
    (result, key, index) => ({
      ...result,
      [key]: RATING_TEMPLATE.map(
        ({ star }, el) => ({ star, value: (el === index) || (index ? (el === (index + 1)) : false) })
      )
    }),
    {}
  )
);
export const BUDGET_MAX_RANGE = 50;
export const ROOMING_STATUS_I18N_MAP = Object.freeze({
  [ROOMING_STATUS_WAIT_FOR_CONFIRMATION]: "hotel_info.roomingStatusWait",
  [ROOMING_STATUS_CONFIRMED]: "hotel_info.roomingStatusConfirm",
  [ROOMING_STATUS_CANCELED]: "hotel_info.roomingStatusCancel"
});
export const COLLAPSED_TRIPS_STORAGE_KEY = "collapsed_trips";
export const USER_GROUP_OWNER = "owner";
export const CONSOLE_LEVEL_NORMAL = "normal";
export const CONSOLE_LEVEL_INFO = "info";
export const CONSOLE_LEVEL_WARNING = "warn";
export const CONSOLE_LEVEL_ERROR = "error";
export const CONSOLE_LOG_COLORS = {
  [CONSOLE_LEVEL_NORMAL]: "#444444",
  [CONSOLE_LEVEL_INFO]: "#04B9E6",
  [CONSOLE_LEVEL_WARNING]: "#f6a623",
  [CONSOLE_LEVEL_ERROR]: "#f44336"
};
export const USER_STATUS_NOT_COMPLETED = "not_completed";
export const USER_STATUS_ACTIVE = "active";
export const USER_STATUS_BLOCKED = "blocked";

export const FOC_DISPLAING_START_DATE = new Date("2021-03-12T00:00:00.000Z");

export const DEFAULT_PREFERRED_HOTELS_QUOTA = 10;
export const INCREASE_QUOTA_REQUEST_STATUSES = {
  IN_PROGRESS: "in-progress",
  APPROVED: "approved",
  DECLINED: "declined"
};
